export const environment = {
  production: false,
  env: 'base', // should never see base...
  apiUrlV3: 'https://localhost:5001/api/v3',
  apiUrl: 'https://localhost:5000/api',
  billingApiUrl: 'https://localhost:5002/api',
  googleApi: 'https://mybusiness.googleapis.com/v4',
  defaultStripePublicKey: 'pk_test_f1y3zSBWhe4bj6IHwQjmH4yi', // Just for initializaition purposes
  branding: {},
  hideComparisonInGrader: false,
  hideSignUpButton: true,
  hideContactUsButton: false,
  firebase: {
    apiKey: "AIzaSyCB7UWOtVNU6b9FvxiA_M9yl_V3F41tYPM",
    authDomain: "gmb-review-dashboard.firebaseapp.com",
    databaseURL: "https://gmb-review-dashboard.firebaseio.com",
    projectId: "gmb-review-dashboard",
    storageBucket: "gmb-review-dashboard.appspot.com",
    messagingSenderId: "156411061417",
    appId: "1:156411061417:web:2e9fe7e629c4735ed535b8",
    measurementId: "G-WMPRML7KG5"
  }
};
