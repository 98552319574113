import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  constructor() {
    // This is intentional
  }

  fireConfig() {
    // TODO: This should be taken from environment.ts, why is not and the settings
    // are duplicated here?
    return {
      apiKey: "AIzaSyCB7UWOtVNU6b9FvxiA_M9yl_V3F41tYPM",
      authDomain: "gmb-review-dashboard.firebaseapp.com",
      databaseURL: "https://gmb-review-dashboard.firebaseio.com",
      projectId: "gmb-review-dashboard",
      storageBucket: "gmb-review-dashboard.appspot.com",
      messagingSenderId: "156411061417",
      appId: "1:156411061417:web:2e9fe7e629c4735ed535b8",
      measurementId: "G-WMPRML7KG5"
    };
  }
}
