// dep
import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import * as _ from 'lodash'
import jsPDF from "jspdf"
import "jspdf-autotable"
import { Router } from '@angular/router'

// app
import User from '../../constants/firestore/user'
import { Sort } from '../../constants/sort'
import { AuthService } from '../../services/auth.service'
import { DomainService } from '../../services/domain.service'
import { AdvancedPageable } from '../../constants/pageable'
import { SubscriptionService } from '../../services/subscription.service'
import { debounceTime, switchMap } from 'rxjs/operators'
import { clear } from 'console'

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls:  ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit {
  public user: User;
  public domain: string;
  public sort: Sort = {sortBy: 'created', direction: 'desc'};
  public previousPageable: { size: any; page: any };
  public pagination: any;
  public filterField: string;
  public domainValue;
  public loading = true;
  public firstLoading = true
  // public isAllSelected: boolean;
  public paginate: AdvancedPageable = { page: 1, size: 25,  totalPages: 1};
  public skipRecords = 0;
  public dataSource: any;
  public inputSearch: string = null
  public domainsOptions = [];
  public statusOptions = ["PAID", "UNPAID", "TRIAL"];
  public cols = [
    { header: 'Name & Emails', field: 'name'},
    { header: 'Status', field: 'status' },
    { header: 'Essential', field: 'essential', isCurrency: true},
    { header: 'Basic', field: 'basic', isCurrency: true},
    { header: 'Ultimate', field: 'ultimate', isCurrency: true},
    { header: 'Created On', field: 'created'},
    { header: 'Next Billing Date', field: 'nextDue'},
    { header: 'Last Invoice', field: 'lastInvoicePaidDate'},
    { header: 'Domain', field: 'domain'}
  ];
  public readonly DOMAIN_COLS = [
    {header: 'Domain', field: 'domain'},
    {header: 'Essential', field: 'essential'},
    {header: 'Basic', field: 'basic'},
    {header: 'Ultimate', field: 'ultimate'}
  ];
  public domainDataSource = [];
  public statusFilter = "";
  public sortableColumns = ['name', 'status', 'nextDue', 'lastInvoicePaidDate', 'domain', 'created']
  
  private _searchTime: any;

  constructor(
    public auth: AuthService,
    public domainService: DomainService,
    private _cdr: ChangeDetectorRef,
    private _subscriptionService: SubscriptionService,
    private _router: Router
  ) {
    this.user = this.auth.session;
  }

  ngOnInit(): void {
    this.domainValue = "app.maplabs.com"
    this.domainService.getDomains().subscribe(
      res => {
      if (res.domains.length > 0) {
        this.domainsOptions =  this.domainService.getOptionsDomains(res.domains);
        this.getSubscription();
      }
    },
      err => this.loading = false
    );
  }

  getSubscription (waitMs=0): void {

    if (this._searchTime){
      clearTimeout(this._searchTime)
    }

    this._searchTime = setTimeout(()=> {
    this.loading = true;
    this._subscriptionService.getSubscriptionsPaginated(this.paginate, this.sort, this.domainValue, this.inputSearch, this.statusFilter)
    .subscribe(
      subscriptions => {
        this.dataSource = [];
        const data = subscriptions['items'];
        this.paginate.totalPages = subscriptions["totalPages"]
        data.forEach(el => {
            el.id = el._id.$oid;
            el.nextDue = el.nextDueDate ? new Date(el.nextDueDate?.$date) : null;
            el.lastInvoicePaidDate = el.lastInvoicePaidDate ? new Date(el.lastInvoicePaidDate?.$date) : null;
            el.created = new Date(el.createdAt?.$date);
            el.name = `${el.user.displayName} - ${el.user.email}`;
            this.dataSource.push(el);
        });
        this.dataSource = this.dataSource.sort((a, b) => {return b.lastInvoicePaidDate - a.lastInvoicePaidDate});
        this.getProducts();
        this.getSubscriptionByDomain();
        this.loading = false;
        this.firstLoading = false
      },
      err => {
        this.loading = false;
        this.firstLoading = false
    });
  }, waitMs)
    this._cdr.detectChanges();
  }

  getProducts() {
    let data = {};
    this.dataSource.forEach(el => {
      data = {};
      (el?.products || []).map(el => el.pid).filter((idx) => {
        data.hasOwnProperty(idx) ? data[idx] += 1 : data[idx] = 1;
      });
      Object.keys(data).forEach(d => el[d] = data[d])
    });
  }

  getSubscriptionByDomain() {
    let data = {};
    const domainsSet = [...new Set(this.dataSource.map(el => el.domain))];
    const domains = domainsSet.filter(domain => !!domain)

    domains.forEach((el) => {
      const items = this.dataSource.filter(i => i.domain == el);
      data = {};
      items.forEach(i => {
        i?.products?.map(i => i.pid).filter((indice) => {
          data.hasOwnProperty(indice) ? data[indice] += 1 : data[indice] = 1;
        });
      });

      this.domainDataSource.push({
        domain: el,
        data
      });
    });
  }

  // TODO: Unused, remove
  // getStatus() {
  //   const status = this.dataSource.map(el => el.status);
  //   const statusSet = [...new Set(status)];
  //   this.statusOptions = statusSet.filter(el => el != null && el != undefined);
  // }

  exportPdf(table) {
    const data = table.filteredValue || this.dataSource
    const exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
    const doc = new jsPDF('p','pt', [window.innerWidth, window.innerHeight]);
    doc['autoTable'](exportColumns, data);
    doc.save("subscription.pdf");
  }

  pageChange(event: any): void {
    this.skipRecords = event.first
    this.paginate.page = event.first/event.rows + 1
    this.paginate.size = event.rows
    this.getSubscription()
    
  }

  sortChange(event: any): void{
    this.sort = {
      sortBy: event.field,
      direction: event.order === -1 ? "desc": "asc"
    }
    this.getSubscription()
  }

  domainSearch(selectedDomain: string): void{
    this.domainValue = selectedDomain
    this.getSubscription()
  }

  inputFilterSeach(searchValue: string): void{
    this.inputSearch = searchValue
    this.paginate.page = 1
    this.getSubscription(1000)
  }

  initSort(): void {
    this.sort = {
      sortBy: 'created',
      direction: 'desc',
    };
  }

  selectSubscription(data) {
    this._router.navigate(
      [`admin/group/${data.user.uid}`],
      {state: {
          'tabIndex': 2,
          'gid': data.gid
        }
      }
    ).then();
  }

  getTotalTooltip(item) : string | null {
    return (item.lastInvoicePaidDate == null ? 'This subscription has no invoice' : null);
  }

  filterDomain(table, value, locTable) {
    table.filterGlobal(value, 'contains');
    locTable.filterGlobal(value, 'contains');
  }

  clearTable(table, locTable) {
    this.inputSearch = null;
    this.domainValue = null;
    table.clear();
    locTable.clear();
  }

  checkSortableColumn(colName: string): boolean{
    return this.sortableColumns.includes(colName)
  }

  filterTable(filterValue: string){
   this.statusFilter=filterValue
   this.getSubscription()
  }

}

