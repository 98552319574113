// dep
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { forkJoin } from 'rxjs'

// app
import { WhiteLabelService } from '../../../services/white-label.service'
import { InvoiceService } from '../../../services/invoice.service'
import { ModalService } from '../../../services/modal.service'
import { ProductsService } from '../../../services/products.service'

@Component({
  selector: 'app-export-invoice-pdf',
  templateUrl: './export-invoice-pdf.component.html',
  styleUrls: ['./export-invoice-pdf.component.scss']
})
export class ExportInvoicePdfComponent implements OnInit {
  public branding;
  public invoice;
  public dataSource;
  public cols = [
    { header: 'Description', field: 'description' },
    { header: 'Price', field: 'price', isCurrency: true },
    { header: 'Discount', field: 'discount', isCurrency: true },
    { header: 'Platform Fee', field: 'fee', isCurrency: true },

  ];
  public isLoading = true;

  constructor(
    private wl: WhiteLabelService,
    private invoiceServ: InvoiceService,
    private productService: ProductsService,
    private route: ActivatedRoute,
    private modal: ModalService,
    private router: Router
  ) {
    const { id, gid }  = this.route.snapshot.params

    forkJoin([
      this.wl.getDynamicWhiteLabel(),
      this.invoiceServ.getInvoices(),
      this.productService.getProducts()
    ]).subscribe(
      res => {
        this.branding = res[0].branding;
        const inv     = res[1].data.find(el => el._id.$oid == id)
        this.invoice = inv
        if (inv.gid === gid){
          inv.id                = inv._id.$oid;
          inv.displayName       = inv.user.displayName;
          inv.email             = inv.user.email;
          inv.created           = inv.createdAt.$date;
          inv.updated           = inv.updatedAt.$date;
          inv.subscriptionCount = this.invoiceServ.getSubscriptionCount(inv)

          this.dataSource = inv.items;
          this.isLoading = false;
        }else{
          this.modal.openConfirmModal('Alert', 'The Url given does not correspond to any invoice',
          (() => {
            this.router.navigate(['login'])
          }));
        }
      }
    );
  }

  ngOnInit(): void {
  }

  exportPdf() {
    this.invoiceServ.exportInvoicePdf(this.branding, this.invoice);
  }

}
