import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// import { TextReaderComponent } from './pages/text-reader/text-reader.component';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { DashboardAdminComponent } from './pages/dashboard-admin/dashboard-admin.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { UsersComponent } from './pages/users/users.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { InvoiceDetailsComponent } from './pages/invoices/invoices-details/invoices-details.component';
// import { UpcomingInvoicesComponent } from './pages/upcoming-invoices/upcoming-invoices.component';
import { SubscriptionsComponent } from './pages/subscriptions/subscriptions.component';
import { GroupDetailsComponent } from './pages/group-details/group-details.component';
import { ProductsComponent } from './pages/products/products.component';
import { ViewLogsComponent } from './pages/view-logs/view-logs.component';
import { GradeLeadsComponent } from './pages/grade-leads/grade-leads.component';
import { DomainsComponent } from './pages/domains/domains.component';
import { ExportInvoicePdfComponent } from './pages/invoices/export-invoice-pdf/export-invoice-pdf.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'login',
        data: { title: 'Login' },
        component: LoginComponent
      },
      {
        path: 'forgot-password',
        data: { title: 'Forgot password' },
        component: ForgotPasswordComponent
      }
    ]
  },
  // {
  //   path: 'text-reader/:text',
  //   component: TextReaderComponent,
  // },
  {
    path: 'admin',
    component: DashboardAdminComponent,
    children: [
      {
        path: '',
        component: SettingsComponent,
      },
      {
        path: 'settings',
        component: SettingsComponent,
      },
      {
        path: 'products',
        component: ProductsComponent,
      },
      {
        path: 'users',
        component: UsersComponent,
      },
      {
        path: 'domains',
        component: DomainsComponent,
      },
      {
        path: 'subscriptions',
        component: SubscriptionsComponent,
      },
      {
        path: 'view-logs',
        component: ViewLogsComponent,
      },
      {
        path: 'grade-leads',
        component: GradeLeadsComponent,
      },
      // {
      //   path: 'upcoming-invoices',
      //   component: UpcomingInvoicesComponent,
      // },
      {
        path: 'invoices',
        component: InvoicesComponent,
      },
      {
        path: 'invoice-details',
        component: InvoiceDetailsComponent
      },
      {
        path: 'group/:id',
        component: GroupDetailsComponent
      }
    ]
  },
  {
    path: 'export-invoice-pdf/:id/:gid',
    component: ExportInvoicePdfComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
