import {environment as defaultEnvironment} from './environment.default';

export const environment = {
  ...defaultEnvironment,
  env: 'prod',
  production: true,
  apiUrlV3: 'https://api-ccx5mqu6oa-ue.a.run.app/api/v3',
  apiUrl: 'https://api.maplabs.com/api',
  billingApiUrl: 'https://billing-api-ccx5mqu6oa-ue.a.run.app/api',
};
