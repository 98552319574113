<div class="preview-invoice">
  <app-skeletons [visibleSection]="'export-invoice-pdf'" *ngIf="isLoading"></app-skeletons>

  <ng-container *ngIf="!isLoading">
    <div class="content__header d-flex justify-content-between align-items-center">
      <h1 class="m--0">
        <strong>Invoice</strong>
        <em class="pi pi-download copy-clipboard" matTooltip="Export Pdf" matTooltipPosition="below"
        matTooltipClass="tooltip tooltip--black" (click)="exportPdf()">
      </em>
      </h1>
      <img *ngIf="branding?.pdfLogo" src="{{branding?.pdfLogo}}" alt="Upload photos here." class="mb--10">
    </div>

    <div>
      <ul>
        <li>
          <p><span class="fw--semibold">Invoice Number:</span> {{invoice?.id || '-'}}</p>
        </li>
        <li>
          <p><span class="fw--semibold">Created On: </span>{{invoice?.created | date: 'MM/dd/yy' || '-'}}</p>
        </li>
        <li>
          <p><span class="fw--semibold">Bill To:</span> {{invoice?.email || '-'}}</p>
        </li>
      </ul>

      <ul>
        <section>
          <li>
            <p><span class="fw--semibold">Currency:</span> {{invoice?.currency || '-'}}</p>
          </li>
          <li>
            <p><span class="fw--semibold">Total =</span> {{invoice?.invoiceTotal | currency}}</p>
          </li>
          <li>
            <p><span class="fw--semibold">Total Discount =</span> {{invoice?.invoiceDiscountTotal | currency}}</p>
          </li>
          <li>
            <p><span class="fw--semibold">Total Platform Fees =</span> {{invoice?.invoiceFeeTotal | currency}}</p>
          </li>
        </section>

        <section>
          <li>
            <p class="fw--semibold">Subscription Count</p>
          </li>
          <li>
            <p>- Ultimate: {{invoice?.subscriptionCount?.Ultimate || '-'}}</p>
          </li>
          <li>
            <p>- Basic: {{invoice?.subscriptionCount?.Essential || '-'}}</p>
          </li>
          <li>
            <p>- Essential: {{invoice?.subscriptionCount?.Basic || '-'}}</p>
          </li>
        </section>
      </ul>

      <p-table #dt [value]="dataSource" [columns]="cols" styleClass="p-datatable" [rowHover]="true"
      [responsive]="true"
      [paginator]="true" [rows]="100" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100]"
      currentPageReportTemplate="{{dt.filteredValue?.length || dataSource?.length}} entries">
          <ng-template pTemplate="header">
          <tr>
              <ng-container *ngFor="let col of cols">
              <th>
                  <div class="p-d-flex p-jc-between p-ai-center">
                  {{col.header | titlecase}}
                  </div>
              </th>
              </ng-container>
          </tr>
          </ng-template>
          <ng-template pTemplate="body" let-dataSource>
          <tr class="p-selectable-row">
              <td><span [outerHTML]="dataSource?.description | isEmpty"></span></td>
              <td><span [outerHTML]="dataSource?.productPrice | currency | isEmpty"></span></td>
              <td><span [outerHTML]="dataSource?.discountRate | currency | isEmpty"></span></td>
              <td><span [outerHTML]="dataSource?.productFee | currency | isEmpty"></span></td>
          </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
          <tr>
              <td colspan="8">No items found.</td>
          </tr>
          </ng-template>
      </p-table>

    </div>
  </ng-container>
</div>
