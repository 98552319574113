// dep
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Sort } from '../constants/sort'
import { Pageable } from '../constants/pageable'

// app
import { environment as ENV } from '@environment'
import Subscription, { SubscriptionDetailed } from '../constants/subscription'

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private http: HttpClient) {}

  fetchSubscription(gid : string): Promise<{data : SubscriptionDetailed}> {
    return this.http.get<{data : SubscriptionDetailed}>(`${ENV.billingApiUrl}/subscription/${gid}`).toPromise()
  }

  fetchSubscriptionsAll(): Observable<{data : Subscription[]}> {
    return this.http.get<{data : Subscription[]}>(`${ENV.billingApiUrl}/subscription/all`)
  }

  getSubscriptionsPaginated(
    paginate: Pageable, 
    sort: Sort,
    domain: string,
    inputSearch: string,
    statusFilter: string
    ): Observable<any> {
      const reverse = sort.direction === "desc"? 1 : 0
      let url = `${ENV.billingApiUrl}/subscription/all-paginated?page=${paginate.page}&pageSize=${paginate.size}&sortKey=${sort.sortBy}&reverse=${reverse}`
      if (domain){
        url += `&domain=${domain}`
      }
      if (inputSearch){
        url += `&query=${inputSearch}`
      }
      if (statusFilter){
        url += `&status=${statusFilter}`
      }
      return this.http.get(url)
  }

  changeBillingOverride(gid : string, billing_override_enabled : boolean): Promise<any> {
    const body = {
      'billingOverride': billing_override_enabled
    }
    return this.http.put(`${ENV.billingApiUrl}/subscription/${gid}/billing-override`, body).toPromise();
  }

}
