import {Component} from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash'

import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'maplabs';

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    if (document.location.href.includes('export-invoice-pdf')) {
      const id  = document.location.href.split('/')[(document.location.href.split('/')).length -2];
      const gid = document.location.href.split('/')[(document.location.href.split('/')).length -1];
      this.router.navigate([`/export-invoice-pdf/${id}/${gid}`])
    } else {
      this.router.navigate(['login'])
    }
  }
}

