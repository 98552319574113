// dep
import firebase from 'firebase/compat/app'


export enum CollectionTarget {
  MAPLABS_END_CUSTOMER     = "MAPLABS_END_CUSTOMER",
  MAPLABS_OUT_OF_BAND      = "MAPLABS_OUT_OF_BAND",
  WL_END_CUSTOMER          = "WL_END_CUSTOMER",
  WL_END_CUSTOMER_NO_FEE   = "WL_END_CUSTOMER_NO_FEE",
  WL_OWNER                 = "WL_OWNER",
  WL_OWNER_ONLY_FEE        = "WL_OWNER_ONLY_FEE",
  WL_OWNER_ONLY_FEE_NO_OOB = "WL_OWNER_ONLY_FEE_NO_OOB",
  WL_OUT_OF_BAND           = "WL_OUT_OF_BAND",
}

export enum PaymentSource {
    END_CUSTOMER_CC = 'END_CUSTOMER_CC',
    WL_OWNER_CC     = 'WL_OWNER_CC',
    OUT_OF_BAND     = 'OUT_OF_BAND'
}


// TODO: This should match the Mongo 'subscriptions' schema
export default interface Subscription {
  status: 'TRIAL' | 'PAID' | 'UNPAID'

  dismissModalDatePicker?: firebase.firestore.Timestamp;

  billingOverride : boolean
  domain : string

  // TODO: Type better:
  nextDueDate : any // Date
  lastInvoicePaidDate : any
  user? : any
  id? : any
  _id? : any
  trialEnd? : any
  discount? : number
  created? : Date
  createdAt : any 
  startDate? : Date
  nextDue : Date | null
  products : any[]
  name? : string
}

// Matchs billing-api route /subscriptions/<gid>
export interface SubscriptionDetailed extends Subscription {
  generatesOwnerInvoices : boolean, 
  collectionByBillingOverride :  { [bo in 'true' | 'false'] : { collectionTarget      : keyof typeof CollectionTarget,
                                                                requiresPaymentMethod : boolean,
                                                                paymentSource         : keyof typeof PaymentSource 
                                                              }}
}

