// dep
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Pageable } from '../constants/pageable'
import { Sort } from '../constants/sort'
import jsPDF from "jspdf"
import "jspdf-autotable"

// app
import { environment as ENV } from '@environment'

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  public readonly cols = [
    { header: 'Description',  field: 'description' },
    { header: 'Price',        field: 'price',    isCurrency: true },
    { header: 'Discount',     field: 'discount', isCurrency: true },
    { header: 'Platform Fee', field: 'fee',      isCurrency: true }
  ] as const;

  constructor(private http: HttpClient) {}

  getInvoices(gid? : string): Observable<any> {
    if (gid) {
      return this.http.get(`${ENV.billingApiUrl}/invoice/gid/${gid}?summary=1`)
    } else {
      return this.http.get(`${ENV.billingApiUrl}/invoice/all?summary=1`)
    }
  }

  getInvoicesPaginated(
    paginate: Pageable, 
    sort: Sort,
    domain: string,
    inputSearch: string,
    status: string
    ): Observable<any> {
      const reverse = sort.direction === "asc"? 0 : 1
      let url = `${ENV.billingApiUrl}/invoice/all-paginated?summary=1&page=${paginate?.page}&pageSize=${paginate?.size}&sortKey=${sort?.sortBy}&reverse=${reverse}`
      if (domain){
        url += `&domain=${domain}`
      }
      if (inputSearch){
        url += `&query=${inputSearch}`
      }
      if (status){
        url += `&status=${status}`
      }
      return this.http.get(url)
  }

  getInvoiceById(id : string): Observable<any> {
    return this.http.get(`${ENV.billingApiUrl}/invoice/${id}?expanded=1`);
  }

  getInvoiceLogs(id : string): Observable<any> {
    return this.http.get(`${ENV.billingApiUrl}/invoice/${id}/logs`);
  }

  async fetchInvoiceNextMonthDraft(gid : string, summary = false) : Promise<any> {
    // &pricingVersion=2
    return (await this.http.get(`${ENV.billingApiUrl}/invoice/subscription/draft/${gid}?summary=${summary ? '1' : '0'}`).toPromise() as any).data
  }

  getSubscriptionCount(invoice) : { [pid : string] : number } {
    // TODO: Check if possible to use items.product instead of parsing item description (safer),
    // and use lowercase pids as keys. 
    const qtys = { "Ultimate"  : 0,
                   "Basic"     : 0,
                   "Essential" : 0}
    for(const it of invoice.items) {
      const desc = it.description.toLowerCase()
      const pid  = desc.includes('basic') ? 'Basic' : (desc.includes('ultimate') ? 'Ultimate' : 'Essential')
      qtys[pid]  += 1
    }
    return qtys
  }

  // review if its still necessary after the new pdf method (below)
  exportInvoicePdf(branding, invoice) {
    const exportColumns = this.cols.map(col => ({ title: col.header, dataKey: col.field }));
    const doc = new jsPDF('p', 'px', 'letter');
    const initTxt = 30;

    const format = InvoiceService.formatPrice

    const total         = format(invoice?.invoiceTotal)
    const totalDiscount = format(invoice?.invoiceDiscountTotal)
    const totalFees     = format(invoice?.invoiceFeeTotal)
    const updated       = new Date(invoice?.created).toDateString()
    const body = [
      ...invoice.items.map(el => [el.description, 
                                  format(el.productPrice), 
                                  format(el.discountRate), 
                                  format(el.productFee)]),
      ['Total', total, totalDiscount, totalFees]
    ]

    if (branding?.pdfLogo) {
      const img = new Image();
      img.src = branding.pdfLogo;
      doc.addImage(img, 300, 5, 0, 50);
    }

    doc.setFontSize(12);
    doc.setFont(undefined, 'bold');
    doc.text(`Invoice`, 30, initTxt);
    doc.setFontSize(10);
    doc.setFont(undefined, 'bold');
    doc.text(`Invoice Number: ${invoice?.id || '-'}`, 30, initTxt + 25);
    doc.setFont(undefined, 'normal');
    doc.text(`Created On: ${updated || '-'}`, 30, initTxt + 40);

    doc.text(`Currency: ${invoice?.currency || '-'}`, 30, initTxt + 65);
    doc.text(`Bill To: ${invoice?.email || '-'}`, 30, initTxt + 80);
    doc.text(`Total Platform Fees = ${totalFees}`, 30, initTxt + 95);

    doc.setFont(undefined, 'bold');
    doc.text(`Subscription Count`, 250, initTxt + 65);
    doc.setFont(undefined, 'normal');
    doc.text(`- Ultimate: ${invoice?.subscriptionCount?.Ultimate || '-'}`, 250, initTxt + 80);
    doc.text(`- Basic: ${invoice?.subscriptionCount?.Basic || '-'}`, 250, initTxt + 95);
    doc.text(`- Essential: ${invoice?.subscriptionCount?.Essential || '-'}`, 250, initTxt + 110);

    doc['autoTable'](
      exportColumns,
      body,
      {
        startY: initTxt + 145,
        theme: 'plain',
        styles: {
          fontSize: 10,
        },
        useCss: true,
        columnStyles: {
          0: { cellWidth: 250 },
          1: { cellWidth: 40 },
          2: { cellWidth: 40 },
          3: { cellWidth: 90 }
        },
        willDrawCell: function (data) {
          const cols = exportColumns.map(el => el.title);
          const y = data.cell.y + data.cell.contentHeight;
          if (cols.includes(data.cell.raw)) {
            doc.setLineWidth(1.8);
            doc.setDrawColor(112, 115, 128)
            data.doc.line(data.cell.x, y, data.cell.x + data.cell.width, y);
          }
        }
      }
    );

    doc.save("invoice-details.pdf");
  }

  static formatPrice(price : number) : string {
    return (new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })).format(price)
  }

  fetchInvoicePDF(invId: string ): Observable<Blob> {
    return this.http.get(`${ENV.billingApiUrl}/invoice/${invId}/pdf`,  { responseType: 'blob' })
  }
  
  getTooltip(invoice) : string {
    const c = invoice?.locationsCount
    return ('Essential: '+`${c?.essential} | `+
            'Basic: '    +`${c?.basic} | `+
            'Ultimate: ' +`${c?.ultimate} `)
  }

}
