import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isEmpty',
})
export class isEmptyPipe implements PipeTransform {

  constructor() {}

  transform(value: string) {
    if(value == '' || value == null) {
      const html = '<span class="isEmpty">-</span>';
      return html;
    } else {
      return value;
    }
  }

}
