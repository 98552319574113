import {Injectable} from '@angular/core';
import {
  AngularFirestore, AngularFirestoreCollection, Query,
} from '@angular/fire/compat/firestore';

/* import {firestore} from 'firebase/app'; */

import { combineLatest, Observable, defer, of } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';

import { Pagination} from '../constants/api-response';
import { LOCATIONS } from '../constants/firestore/collections';
import { Pageable } from '../constants/pageable';

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;

@Injectable()
export class FirestoreService {

  previousPage: Pageable = {size: 5, page: 1};

  constructor(private db: AngularFirestore) {
  }

  public col<T>(ref: CollectionPredicate<T>, queryFn?): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.db.collection<T>(ref, queryFn) : ref;
  }

  paginateUsers<T>(ref: string, domain, order, direction, pageable?: Pageable, next?, prev?): Observable<Pagination> {
    let snapshot: Observable<any> = null;
    let countSnapshot: Observable<any> = null;
    countSnapshot = this.db.collectionGroup<T>(ref, refFn => refFn.orderBy(order, direction)
      .where('registrationDomain', 'in', [domain, domain + ':'])).snapshotChanges().pipe(map(r => r.length));

    if (next) {
      snapshot = this.db.collectionGroup<T>(ref, refFn => refFn.orderBy(order, direction)
        .where('registrationDomain', 'in', [domain, domain + ':'])
        .startAfter(next[order])
        .limit(pageable.size))
        .snapshotChanges();

      return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
    }

    if (prev) {
      snapshot = this.db.collectionGroup<T>(ref, refFn => refFn.orderBy(order, direction)
        .where('registrationDomain', 'in', [domain, domain + ':'])
        .endBefore(prev[order]).limitToLast(pageable.size)).snapshotChanges();

      return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
    }

    snapshot = this.db.collectionGroup<T>(ref, refFn => refFn.orderBy(order, direction)
      .where('registrationDomain', 'in', [domain, domain + ':'])
      .limit(pageable.size)).snapshotChanges();

    return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
  }

  formatPaginationSnapshots(snapshot:Observable<any>,
    ob : Observable<any>,
    pageable: any) {
    return combineLatest(ob, snapshot).pipe(
    filter( d => pageable.page == this.previousPage?.page),
    map( (result) => {
    const tmp = result[1].map(a => {
    const data = a.payload.doc.data();
    const id = a.payload.doc.id;
    return {id, ...data};
    });
    return {data: tmp, count: result[0]}
    }),
    map((data) => this.formatPagination(data.count, pageable, data.data))
    );
  }

  formatPagination(count, pageable, actions) {
    const pages = Math.ceil(count / pageable.size);
    let hasPrev = true;
    let hasNext = true;
    if (pages === pageable.page && pages > 1) {
      hasNext = false;
      hasPrev = true;
    } else if (pages === pageable.page && pages === 1) {
      hasNext = false;
      hasPrev = false;
    } else if (pageable.page === 1 && pages !== 0) {
      hasPrev = false;
      hasNext = true;
    } else if (pageable.page > 1 && pageable.page < pages) {
      hasPrev = true;
      hasNext = true;
    } else {
      hasPrev = false;
      hasNext = false;
    }


    const pagination: Pagination = {
      items: actions,
      total: count,
      per_page: pageable.size,
      page: pageable.page,
      pages,
      hasPrev,
      hasNext,
    };

    return pagination;
  }

  paginateGrades<T>(ref: string, filter, order, direction, pageable?: Pageable, next?, prev?, values?): Observable<Pagination> {
    let snapshot: Observable<any> = null;
    let countSnapshot: Observable<any> = null;
    var query : Query = null;

    var collection = this.db.collection<T>( ref ).doc(filter.domain).collection(LOCATIONS, refFn => {
      query = refFn;

      /** Order BY */
      if( order && direction ) {
        query = query.orderBy(order, direction)
      }

      /** WHERE */
      if( filter ) {
        if ( filter.domain != null ) {
          query = query.where('registrationDomain', '==', filter.domain)
        }
        if ( filter.viewed != null ) {
          query = query.where('viewed', '==', filter.viewed)
        }
      }

      /** Pagination */
      if ( next ) {
        query = query.startAfter( next[order] )
        query = query.limit(pageable.size)
      }
      if ( prev ) {
        query = query.endBefore( prev[order] )
        query = query.limitToLast(pageable.size)
      }

      /** Return query build */
      return query;
    })

    /** snapshotchanges or valueschange */
    if (values) {
      countSnapshot = collection.valueChanges().pipe(map(r => r.length));
      snapshot      = collection.valueChanges();
      return this.formatPaginationValues(snapshot, countSnapshot, pageable);
    }
    else {
      countSnapshot = collection.snapshotChanges().pipe(map(r => r.length));
      snapshot = collection.snapshotChanges();
      return this.formatPaginationSnapshots(snapshot, countSnapshot, pageable);
    }
  }

  formatPaginationValues(snapshot: Observable<any>, ob: Observable<any>, pageable: Pageable) {
    return combineLatest(ob, snapshot).pipe(
      filter( d => pageable.page == this.previousPage.page),
      switchMap( (result) => {
        return of(this.formatPagination(result[0], pageable, result[1]))
      })
    );
  }

  /// with Ids
  public colWithIds$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<any[]> {
    return this.col(ref, queryFn).snapshotChanges().pipe(map(actions => {
      return actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return {id, ...data};
      });
    }));
  }
}



