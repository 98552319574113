<div class="group-details">
  <div class="content__header d-flex justify-content-between align-items-center">
    <h1 class="m--0"><strong>Group Details</strong></h1>
  </div>

  <div class="content__body box box--rounded box--shadow bg--white">
    <mat-tab-group #tabGroup [selectedIndex]="activeTab">

      <!-----------------------------------------------------------------------
      | Group Details
      `----------------------------------------------------------------------->

      <mat-tab label="Group Details">
        <app-skeletons [visibleSection]="'groups-details'" *ngIf="loadingGroup || loadingUsers || loadingSubscription"></app-skeletons>

        <section *ngIf="!loadingGroup && !loadingUsers && !loadingSubscription">
          <div>
            <div class="box__header">
              <h2 class="txt--capitalize txt--black m--0"><strong>Group Status</strong></h2>
            </div>

            <form [formGroup]="formStatus">
              <div class="box__content box--padding-xxs h--200">

                <div class="row mb--20 h--110">

                  <div class="col-6 data-group">
                    <ul class="col-6">
                      <li class="mb--15"><p>Created On:</p> {{group?.createdAt | date: 'MM/dd/yy' || "-"}}</li>
                      <li class="mb--15"><p>Users:</p> {{ dataSource?.length || 0 }}</li>
                    </ul>
                    <ul class="col-6">
                      <li class="mb--15"><p>Essential Locations:</p> {{ group?.freeLocationsCount     || 0 }}</li>
                      <li class="mb--15"><p>Basic Locations:</p>     {{ group?.basicLocationsCount    || 0 }}</li>
                      <li class="mb--15"><p>Ultimate Locations:</p>  {{ group?.ultimateLocationsCount || 0 }}</li>
                    </ul>
                  </div>

                  <div class="col-6">
                    <div class="flex-column mb--15">
                      <mat-label class="mb--5">GID
                        <i class="fa fa-clipboard copy-clipboard" matTooltip="Copy to clipboard" matTooltipPosition="below"
                          matTooltipClass="tooltip tooltip--black" [cdkCopyToClipboard]="formStatus.controls.gid.value">
                        </i>
                      </mat-label>
                      <input type="text" formControlName="gid" disabled
                        class="input-group__field input-group__field--sm">
                    </div>

                    <div class="flex-column mb--15">
                      <mat-label class="mb--5">Company Name</mat-label>
                      <input type="text" formControlName="company"
                        class="input-group__field input-group__field--sm">
                    </div>

                  </div>

                </div>

                <div class="txt--right">
                  <button color="primary" mat-flat-button class="btn btn--lg" (click)="onSaveGroupDetails()">Save
                  </button>
                </div>
              </div>
            </form>
          </div>

        </section>
      </mat-tab>


      <!-----------------------------------------------------------------------
      | Users
      `----------------------------------------------------------------------->
      
      <mat-tab label="Users">
        <app-skeletons [visibleSection]="'groups-users-details'" *ngIf="loadingUsers"></app-skeletons>

        <p-table *ngIf="!loadingUsers" #userDT [value]="dataSource" [columns]="USER_COLS" styleClass="p-datatable" [rowHover]="true"
        [responsive]="true"
        [paginator]="true" [rows]="100" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100]"
        currentPageReportTemplate="{{userDT.filteredValue?.length || dataSource?.length}} entries" (onPage)="pageChange($event)"
        [filterDelay]="0" [globalFilterFields]="['email', 'displayName', 'role', 'createdOn', 'signedOn']">
          <ng-template pTemplate="caption">
            <div class="table-header">
              <div class="input-field--search filter-table">
                <section class="table-actions">
                  <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Clear filter and sort" matTooltipPosition="below" matTooltipClass="tooltip tooltip--black" (click)="clearTable(userDT)">
                    <i class="pi pi-filter-slash"></i>
                  </button>
                  <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Export CSV" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="userDT.exportCSV()">
                    <i class="pi pi-file"></i>
                  </button>
                  <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Export PDF" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="exportPdf('users', USER_COLS, userDT)">
                    <i class="pi pi-file-pdf"></i>
                  </button>
                </section>
                <section class="table-filter">
                  <div class="table__icon table__icon--lg">
                    <i class="fas fa-search"></i>
                  </div>
                </section>
                <input pInputText type="text" [ngModel]="filterField" (input)="userDT.filterGlobal($event.target.value, 'contains')" placeholder="Type to search" />
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <ng-container *ngFor="let col of USER_COLS">
                <th pSortableColumn="{{col.field}}" [class.is-currency]="false">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    {{col.header | titlecase}}
                    <p-sortIcon field="{{col.field}}"></p-sortIcon>
                    <p-columnFilter *ngIf="col.field !== 'createdOn' && col.field !== 'signedOn'" type="text" field="{{col.field}}" display="menu"></p-columnFilter>
                    <p-columnFilter *ngIf="col.field === 'createdOn' || col.field === 'signedOn'" type="date" field="{{col.field}}" display="menu"></p-columnFilter>
                  </div>
                </th>
              </ng-container>
              <th>
                <div class="txt--right">Actions</div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-dataSource>
            <tr class="p-selectable-row">
              <td class="td-width-xm align-left-table"><span [outerHTML]="dataSource?.email | isEmpty"></span></td>
              <td class="td-width-xxm"><span [outerHTML]="dataSource?.displayName | titlecase | isEmpty"></span></td>
              <td><span [outerHTML]="dataSource?.role | titlecase | isEmpty"></span></td>
              <td><span [outerHTML]="dataSource?.createdOn | date: 'MM/dd/yy' | isEmpty"></span></td>
              <td><span [outerHTML]="dataSource?.signedOn  | date: 'MM/dd/yy' | isEmpty"></span></td>
              <td class="align-right-table">
                <button mat-flat-button color="primary" class="btn btn-icon mr--5"
                    (click)="onImpersonate(dataSource)" matTooltip="Impersonate">
                  <i class="far fa-eye"></i>
                </button>
                <button mat-flat-button color="primary" class="btn btn-icon mr--5"
                    (click)="resetPassword(dataSource)" matTooltip="Reset password">
                  <i class="far fa-lock-alt"></i>
                </button>
                <button mat-flat-button color="primary" class="btn btn-icon mr--5"
                    (click)="updateRole(dataSource)" matTooltip="To {{role(dataSource)}}">
                  <i class="far fa-power-off"></i>
                </button>
                <button mat-flat-button color="primary" class="btn btn-icon"
                    (click)="deleteUser(dataSource)" matTooltip="Delete">
                  <i class="far fa-trash-alt"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="8">No users found.</td>
            </tr>
          </ng-template>
        </p-table>
      </mat-tab>


      <!-----------------------------------------------------------------------
      | Subscription Details
      `----------------------------------------------------------------------->

      <mat-tab label="Subscription Details">
        <app-skeletons [visibleSection]="'groups-subs-details'" *ngIf="loadingSubscription"></app-skeletons>

        <section *ngIf="!loadingSubscription">
          <div>
            <div class="box__header">
              <h2 class="txt--capitalize txt--black m--0"><strong>Subscription details</strong></h2>
            </div>

            <div class="box__content box--padding-xxs h--200">
              <div class="row mb--20 h--110">
                <div class="col-6">
                  <div class="flex-column">
                    <ul>
                      <li>Customer:        <span [outerHTML]="subscription?.user?.displayName | isEmpty"></span></li>
                      <li>Created On:      <span [outerHTML]="subscription?.created | date: 'MM/dd/yy' | isEmpty"></span></li>
                      <li>Subscription ID: <span [outerHTML]="subscription?.id | isEmpty"></span>
                        <i class="fa fa-clipboard copy-clipboard" matTooltip="Copy to clipboard" matTooltipPosition="below"
                           matTooltipClass="tooltip tooltip--black" [cdkCopyToClipboard]="subscription?.id">
                        </i>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-6">
                  <div class="flex-column">
                    <ul>
                      <li>
                        Status: 
                        <span [outerHTML]="subscription.status | titlecase | isEmpty"></span>
                        <span *ngIf="subscription.status == 'TRIAL'"> (<span [outerHTML]="subscription?.trialEnd | date: 'MM/dd/yy' | isEmpty"></span>)</span>
                      </li>
                      <li *ngIf="subscription?.discount">Discount: {{subscription.discount}} %</li>
                      <li>
                        UID: 
                        <span [outerHTML]="subscription?.user?.uid | isEmpty"></span>
                        <i class="fa fa-clipboard copy-clipboard" matTooltip="Copy to clipboard" matTooltipPosition="below"
                          matTooltipClass="tooltip tooltip--black" [cdkCopyToClipboard]="uid">
                        </i>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="box__header">
              <h2 class="txt--capitalize txt--black m--0"><strong>Pricing</strong></h2>
            </div>

          <div>
            <p-table 
              #subsDT 
              [value]="dataSourceSubs" 
              [loading]="loadingSubscriptionTable" 
              [columns]="SUBSCRIPTIONS_COLS" 
              styleClass="p-datatable" 
              [rowHover]="true"
              [responsive]="true"
              [paginator]="true" 
              [rows]="100" 
              [showCurrentPageReport]="true"
              [rowsPerPageOptions]="[10,25,50,100]" 
              currentPageReportTemplate="{{subsDT.filteredValue?.length || dataSourceSubs?.length}} entries"
              [filterDelay]="0" 
              [globalFilterFields]="['locationName', 'locationAddress', 'pid', 'price', 'net', 'created']">
                <ng-template pTemplate="caption">
                  <div class="table-header">
                    <div class="input-field--search filter-table">
                      <section class="table-actions">
                        <button *ngIf="isSuperAdmin" mat-flat-button color="primary" class="btn btn-icon" matTooltip="Add Product"
                          matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="openAddProductModal('add')">
                          <i class="pi pi-plus"></i>
                        </button>
                        <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Clear filter and sort"
                          matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="clearTable(subsDT)">
                          <i class="pi pi-filter-slash"></i>
                        </button>
                        <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Export CSV" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="subsDT.exportCSV()">
                          <i class="pi pi-file"></i>
                        </button>
                        <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Export PDF" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="exportPdf('subscription', SUBSCRIPTIONS_COLS, subsDT)">
                          <i class="pi pi-file-pdf"></i>
                        </button>
                      </section>
                      <section class="table-filter">
                        <div class="table__icon table__icon--lg">
                          <i class="fas fa-search"></i>
                        </div>
                      </section>
                      <input pInputText type="text" [ngModel]="filterField"
                        (input)="subsDT.filterGlobal($event.target.value, 'contains')" placeholder="Type to search" />
                    </div>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <ng-container *ngFor="let col of SUBSCRIPTIONS_COLS">
                      <th pSortableColumn="{{col.field}}" [class.is-currency]="col.isCurrency">
                        <div class="p-d-flex p-jc-between p-ai-center" [class.txt--right]="col?.field === 'price'">
                          {{col.header | titlecase}}
                          <p-sortIcon field="{{col.field}}"></p-sortIcon>
                          <p-columnFilter *ngIf="col.field === 'locationName' || col.field === 'locationAddress'" type="text" field="{{col.field}}" display="menu"></p-columnFilter>
                          <p-columnFilter *ngIf="col.field === 'created'" type="date" field="{{col.field}}" display="menu"></p-columnFilter>
                          <p-columnFilter *ngIf="col.field === 'price' || col.field === 'net'" type="numeric" field="{{col.field}}" display="menu"></p-columnFilter>
                          <p-columnFilter *ngIf="col.field === 'pid'" field="{{col.field}}" matchMode="in" display="menu">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown [ngModel]="value" [options]="productSubsOptions" placeholder="Products" (onChange)="filter($event.value)">
                                </p-dropdown>
                            </ng-template>
                          </p-columnFilter>
                        </div>
                      </th>
                    </ng-container>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-sub>
                  <tr class="p-selectable-row">
                    <td class="td-width-m"><span [outerHTML]="sub?.locationName | isEmpty"></span></td>
                    <td class="td-width-m"><span [outerHTML]="sub?.locationAddress | isEmpty"></span></td>
                    <td><span [outerHTML]="sub?.pid | titlecase | isEmpty"></span></td>
                    <td class="align-right-table"><span [outerHTML]="sub?.price | currency | isEmpty"></span></td>
                    <td class="align-right-table"><span [outerHTML]="sub?.net | currency | isEmpty"></span></td>
                    <td><span [outerHTML]="sub?.created | date: 'MM/dd/yy' | isEmpty"></span></td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="8">No data found.</td>
                  </tr>
                </ng-template>
              </p-table>
          </div>
          </div>
        </section>
      </mat-tab>


      <!-----------------------------------------------------------------------
      | Billing
      `----------------------------------------------------------------------->

      <mat-tab label="Billing">
        <app-skeletons [visibleSection]="'groups-billing'" *ngIf="loadingGroup || loadingUsers || loadingSubscription"></app-skeletons>

        <section *ngIf="!loadingGroup && !loadingUsers && !loadingSubscription">
          <div>

            <!-- Billing Type -->
            <div *ngIf="group.isDomainOwner" class="box__header">
              <h2 class="txt--black m--0 billing-text-desc">
                  <span *ngIf="group.isDomainOwner && subscription.generatesOwnerInvoices">
                    Owner of White Label {{group.domain}}
                  </span>
                  <span *ngIf="group.isDomainOwner && !subscription.generatesOwnerInvoices">
                    Owner of White Label {{group.domain}} (also End Customer)
                  </span>
                  <!-- <span *ngIf="!group.isDomainOwner">
                    End Customer
                  </span>  -->
                </h2>
              </div>
  
            <div class="box__header">
              <h2 class="txt--capitalize txt--black m--0">
                <strong>Credit Cards</strong>
              </h2>
            </div>

            <!-- Credit Cards -->
            <!-- TODO: Refactorize against settings.component.html -->
            <div class="box__content box--padding-xxs">

              <div class="box__content" *ngIf="!loadingCards">
                <div *ngIf="!cards.length" class="billing-text-desc">
                  <!-- Credit Card Required -->
                  No Credit Cards set up
                  <span *ngIf="!requiresPaymentMethod()">
                    - not required
                  </span>
                  <span *ngIf="requiresPaymentMethod()" class="billing-text-warn">
                    - <b>REQUIRED <i class="fa fa-triangle-exclamation"></i></b>
                  </span>

                </div>
                <div *ngIf="cards.length">
                 <div *ngFor="let card of cards; let i = index">
                  <div class="box__item pt--0 mb--20 border-bottom">
                    <div class="row align-items-center">
                      <div class="col-4">
                        <div class="d-flex align-items-center">
                          <div class="credit-card">                          
                            <img src="{{getCardBrandImage(card.brand)}}" alt="{{card.brand}} Logo"/>
                          </div>
                          <div>
                            <p class="mb--5">Credit Card</p>
                            <p class="m--0 txt--black"><strong>**** {{ card?.last4 }}</strong></p>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <p class="mb--5">Expiration Date</p>
                        <p class="m--0 txt--black"><strong>{{ card?.exp_month }}/{{ card?.exp_year }}</strong></p>
                      </div>
                      <div *ngIf="i === 0" class="col-3 review-response review-response--xs review-response--info">
                        <p class="m--5 txt--center txt--blue txt--uppercase" on>
                          <strong>Default</strong>
                        </p>
                      </div>
                      <!--
                      <div *ngIf="i !== 0" class="col-3 review-response review-response--xs review-response--error cursor--pointer">
                        <p class="m--5 txt--center txt--orange txt--uppercase" (click)="handleDefaultSource({card: card})">
                          <strong>Set as default</strong>
                        </p>
                      </div>
                      <div *ngIf="i !== 0" class="col-2">
                        <button (click)="handleDeleteSource({card: card})" mat-flat-button color="warn" class="btn btn-icon">
                          <i class="far fa-trash-alt"></i>
                        </button>
                      </div>
                      -->
                    </div>
                  </div>
                   </div>
                </div>
              </div>
  
  
            </div>
  
            <div class="box__header">
              <h2 class="txt--black m--0">
                <strong>Billing</strong>
              </h2>
            </div>

            <form [formGroup]="formStripe">
              <div class="box__content box--padding-xxs h--200">
                <div class="row mb--20 h--50">

                  <div class="col-6">
                    <!-- Stripe Customer ID Editable field -->
                    <div *ngIf="stripeCustomerDisplayMode === 'FIELD_EDITABLE'"
                         class="flex-column" >
                      <mat-label class="mb--5">
                        Stripe Customer ID
                        <i class="fa fa-clipboard copy-clipboard" 
                           matTooltip="Copy to clipboard" 
                           matTooltipPosition="below"
                           matTooltipClass="tooltip tooltip--black" 
                           [cdkCopyToClipboard]="formStripe.controls.customerId.value">
                        </i>

                        <!-- Stripe Customer Dashboard Link -->
                        <a *ngIf="stripeCustomerDashboardURL"
                           [href]="stripeCustomerDashboardURL" target="_blank">
                          &nbsp;
                          <i class="fa fa-external-link" 
                             matTooltip="Open Customer on Stripe Dashboard" 
                             matTooltipPosition="below"
                             matTooltipClass="tooltip tooltip--black">
                          </i>
                        </a>    
                      </mat-label>

                       <!-- Stripe Customer Dashboard Input -->
                      <input type="text" 
                             formControlName="customerId"
                             class="input-group__field input-group__field--sm"
                             [readOnly]="!billingIsEditable"
                             />
                    </div>

                    <!-- Stripe Customer Link Only -->
                    <div *ngIf="stripeCustomerDisplayMode === 'LINK_ONLY' && stripeCustomerDashboardURL"
                         class="flex-column" >
                      <a [href]="stripeCustomerDashboardURL" target="_blank">
                         Stripe Customer&nbsp;
                       <i class="fa fa-external-link" 
                          matTooltip="Open Customer on Stripe Dashboard" 
                          matTooltipPosition="below"
                          matTooltipClass="tooltip tooltip--black">
                       </i>
                      </a> 
                    </div>

                  </div>

                  <!-- Billing Override field -->
                  <div class="col-6">
                    <div class="flex-column billing-checkbox">
                      <mat-checkbox *ngIf="billingOverrideField_mustBeShown()"
                                    formControlName="billingOverride" 
                                    class="billing-checkbox"
                                    [disabled]="!billingIsEditable"
                                    >
                          Billing Override
                      </mat-checkbox>
                      <div class="box--padding-xxs mat-caption mat-text-disabled billing-text-desc">
                        {{collectionTargetDesc()}}
                      </div> 
                    </div>
                   </div>

                </div>

                <!-- Save button -->
                <div class="txt--right">
                  <button color="primary" mat-flat-button class="btn btn--lg" (click)="onSaveBilling()">
                    Save
                  </button>
                </div>

              </div>
            </form>

          </div>
        </section>
      </mat-tab>


      <!-----------------------------------------------------------------------
      | Invoices
      `----------------------------------------------------------------------->

      <mat-tab label="Invoices">
        <app-skeletons [visibleSection]="'groups-invoice-details'" *ngIf="loadingInvoices">          
        </app-skeletons>

        <p-table *ngIf="!loadingInvoices" 
        #invoicesDT 
        [value]="dataSourceInvoices" 
        [columns]="INVOICES_COLS" 
        styleClass="p-datatable" 
        [rowHover]="true"
        [responsive]="true"
        [paginator]="true" 
        [rows]="100" 
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10,25,50,100]" 
        currentPageReportTemplate="{{invoicesDT.filteredValue?.length || dataSourceInvoices?.length}} entries"
        [filterDelay]="0" 
        [globalFilterFields]="['name', 'status', 'invoiceTotal', 'invoiceFeeTotal', 'invoiceDiscountTotal', 
                               'created', 'updated', 'registrationDomain', 'gid', 'id']">
          <ng-template pTemplate="caption">
            <div class="table-header">
              <div class="input-field--search filter-table">
                <section class="table-actions">
                  <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Clear filter and sort"
                    matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="clearTable(invoicesDT)">
                    <i class="pi pi-filter-slash"></i>
                  </button>
                  <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Export CSV" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="invoicesDT.exportCSV()">
                    <i class="pi pi-file"></i>
                  </button>
                  <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Export PDF" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="exportPdf('invoices', INVOICES_COLS, invoicesDT)">
                    <i class="pi pi-file-pdf"></i>
                  </button>
                </section>
                <section class="table-filter">
                  <div class="table__icon table__icon--lg">
                    <i class="fas fa-search"></i>
                  </div>
                </section>
                <input pInputText type="text" [ngModel]="filterField"
                  (input)="invoicesDT.filterGlobal($event.target.value, 'contains')" placeholder="Type to search" />
              </div>
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <ng-container *ngFor="let col of INVOICES_COLS">
                <th pSortableColumn="{{col?.field}}" [class.is-currency]="col.isCurrency">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    {{col.header | titlecase}}
                    <p-sortIcon field="{{col.field}}"></p-sortIcon>
                    <p-columnFilter *ngIf="col.field === 'name'" type="text" field="{{col.field}}" display="menu"></p-columnFilter>
                    <p-columnFilter *ngIf="col.field === 'invoiceTotal' || col.field === 'totalPlataformFee' || col.field === 'invoiceDiscountTotal'" type="numeric" field="{{col.field}}" display="menu" currency="USD"></p-columnFilter>
                    <p-columnFilter *ngIf="col.field === 'created' || col.field === 'updated'" type="date" field="{{col.field}}" display="menu"></p-columnFilter>
                    <p-columnFilter *ngIf="col.field === 'status'" field="{{col.field}}" matchMode="in" display="menu">
                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-dropdown [ngModel]="value" [options]="statusOptions" placeholder="Status" (onChange)="filter($event.value)">
                        </p-dropdown>
                    </ng-template>
                    </p-columnFilter>
                  </div>
                </th>
              </ng-container>
            </tr>
          </ng-template>
          
          <ng-template pTemplate="body" let-inv>
            <tr class="p-selectable-row">
              <td (click)="selectInvoices(inv)" class="td-width-m align-left-table">
                <span [outerHTML]="inv.name | titlecase | isEmpty"></span>
              </td>
              <td (click)="selectInvoices(inv)">
                <span [outerHTML]="(inv.isDraft ? 'Next (Draft)' : inv.status) | titlecase | isEmpty"></span>
                <span *ngIf="inv.retry >= 1" matTooltip="Number of retries: {{inv.retry}}" matTooltipClass="tooltip tooltip--black"> 
                  ({{inv.retry}}) 
                  <span class="txt--red">*</span>
                </span>
              </td>
              <td (click)="selectInvoices(inv)" class="align-right-table" 
                  matTooltip="{{invoiceService.getTooltip(inv)}}" 
                  matTooltipClass="tooltip tooltip--black">
                <span [outerHTML]="inv.invoiceTotal | isEmpty"></span>
              </td>
              <td (click)="selectInvoices(inv)" class="align-right-table">
                <span [outerHTML]="inv.invoiceDiscountTotal | isEmpty">                  
                </span>
              </td>
              <td (click)="selectInvoices(inv)" class="align-right-table">
                <span [outerHTML]="inv.invoiceFeeTotal | isEmpty">                 
                </span>
              </td>
              <td (click)="selectInvoices(inv)">
                <span *ngIf="inv.isDraft">---</span>
                <span *ngIf="!inv.isDraft" [outerHTML]="inv.created  | date: 'MM/dd/yy' | isEmpty"></span>
              </td>
              <td (click)="selectInvoices(inv)">
                <span *ngIf="inv.isDraft">---</span>
                <span *ngIf="!inv.isDraft" [outerHTML]="inv.updated  | date: 'MM/dd/yy' | isEmpty"></span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="8">No invoices found.</td>
            </tr>
          </ng-template>
        </p-table>

      </mat-tab>

    </mat-tab-group>
  </div>
</div>
