<div class="invoice-details">
  <button mat-icon-button class="btn btn-icon btn-back" matTooltip="Go Back" matTooltipPosition="below"
    matTooltipClass="tooltip tooltip--white" (click)="goBack()">
    <i class="fas fa-long-arrow-left txt--lg"></i>
  </button>
  <div class="content__header justify-content-between">
    <h1 class="m--0"><strong>Invoice Details</strong></h1>
  </div>

  <app-skeletons [visibleSection]="'invoices-details'" *ngIf="loading"></app-skeletons>

  <div class="content__body box box--rounded box--shadow bg--white" *ngIf="!loading">
    <section>
      <div class="box__header">
        <h2 class="txt--capitalize txt--black m--0"><strong>Summary</strong></h2>
        <button mat-flat-button color="accent" class="btn btn--icon btn--md" (click)="exportAllPdf()"> Print PDF from screen
          <i class="pi pi-file-pdf"></i>
        </button>
        <button id="invoice-pdf-button" *ngIf="invHasPdf" mat-flat-button color="primary" class="btn btn--icon btn--md mr--10" (click)="downloadInvoicePdf()"> Download PDF from server
          <i class="pi pi-file-pdf"></i>
        </button>
      </div>

      <div class="box__content box--padding-xxs h--200">
        <div class="row mb--20 h--110">
          <div class="col-5">
            <div class="flex-column">
              <ul>
                <li>Name:  <span [outerHTML]="invoice?.displayName | isEmpty"></span></li>
                <li>Email: <span [outerHTML]="invoice?.email | isEmpty"></span>
                  <i class="fa fa-clipboard copy-clipboard" matTooltip="Copy to clipboard" matTooltipPosition="below"
                    matTooltipClass="tooltip tooltip--black" [cdkCopyToClipboard]="invoice?.email">
                  </i>
                  <i class="fa fa-external-link copy-clipboard" matTooltip="Go to group details"
                    matTooltipPosition="below" matTooltipClass="tooltip tooltip--black" (click)="goDetails()">
                  </i>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-3">
            <div class="flex-column">
              <ul>
                <li>Currency: <span [outerHTML]="invoice?.currency | isEmpty"></span></li>
                <li>
                  Status: <span [outerHTML]="(isDraft ? 'Next (Draft)' : invoice?.status) | titlecase | isEmpty"></span>
                  <span *ngIf="retriesMessages" matTooltip="{{retriesMessages}}" matTooltipPosition="above"
                    matTooltipClass="mat-tooltip--override" class="mat-tooltip tooltip tooltip--info">
                    <em class="fas fa-info"></em>
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-4">
            <div class="flex-column">
              <ul>
                <li>Total:                 <span [outerHTML]="invoice?.invoiceTotal         | currency | isEmpty"></span></li>
                <li>Discount:              <span [outerHTML]="invoice?.invoiceDiscountTotal | currency | isEmpty"></span></li>
                <li>Map Labs Platform Fee: <span [outerHTML]="invoice?.invoiceFeeTotal      | currency | isEmpty"></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="box__header">
        <h2 class="txt--capitalize txt--black m--0"><strong>Invoice Details</strong></h2>
      </div>

      <div class="box__content box--padding-xxs h--200">
        <div class="row mb--20 h--110">
          <div class="col-6">
            <div class="flex-column">
              <ul>
                <li>Invoice Number: <span [outerHTML]="invoiceId | isEmpty"></span><em
                    class="fa fa-clipboard copy-clipboard" matTooltip="Copy to clipboard" matTooltipPosition="below"
                    matTooltipClass="tooltip tooltip--black" [cdkCopyToClipboard]="isDraft ? 'DRAFT' : invoice?._id.$oid"></em>
                </li>
                <li *ngIf="isSuperAdmin">
                  Group Id (Only SuperAdmin): 
                  <span [outerHTML]="invoice?.gid | isEmpty"></span>
                  <em class="fa fa-clipboard copy-clipboard"
                    matTooltip="Copy to clipboard" matTooltipPosition="below" matTooltipClass="tooltip tooltip--black"
                    [cdkCopyToClipboard]="invoice?.gid">
                  </em>
                </li>
                <li>Invoice Type: <span> {{ getInvoiceType(invoice?.recipientType) | titlecase | isEmpty }}</span></li>
                <li>Updated On: <span [outerHTML]="invoice?.updated | date: 'MM/dd/yy' | isEmpty"></span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="box__header">
        <h2 class="txt--capitalize txt--black m--0"><strong>Items</strong></h2>
      </div>

      <div class="box__content box--padding-xxs h--200">
        <div class="row mb--20 h--110">
          <div class="col-12">
            <p-table #dt [value]="dataSource" [columns]="cols" styleClass="p-datatable" [rowHover]="true"
              [responsive]="true" [paginator]="true" [rows]="100" [showCurrentPageReport]="true"
              [rowsPerPageOptions]="[10,25,50,100]"
              currentPageReportTemplate="{{dt.filteredValue?.length || dataSource?.length}} entries"
              (onPage)="pageChange($event)" [filterDelay]="0"
              [globalFilterFields]="['description', 'invoiceTotal', 'invoiceFeeTotal', 'invoiceDiscountTotal']">
              <ng-template pTemplate="caption">
                <div class="table-header">
                  <div class="input-field--search filter-table">
                    <section class="table-actions">
                      <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Clear filter and sort"
                        matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="clearTable(dt)">
                        <i class="pi pi-filter-slash"></i>
                      </button>
                      <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Export CSV"
                        matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="dt.exportCSV()">
                        <i class="pi pi-file"></i>
                      </button>
                      <button mat-flat-button color="primary" class="btn btn-icon" matTooltip="Export PDF" matTooltipPosition="below" matTooltipClass="tooltip tooltip--white" (click)="exportPdf(dt)">
                        <i class="pi pi-file-pdf"></i>
                      </button>
                    </section>
                    <section class="table-filter">
                      <div class="table__icon table__icon--lg">
                        <i class="fas fa-search"></i>
                      </div>
                    </section>
                    <input pInputText type="text" [ngModel]="filterField"
                      (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Type to search" />
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <ng-container *ngFor="let col of cols">
                    <th pSortableColumn="{{col?.field}}" [class.is-currency]="col.isCurrency">
                      <div class="p-d-flex p-jc-between p-ai-center" [class.txt--right]="col?.field === 'price'">
                        {{col.header}}
                        <p-sortIcon field="{{col.field}}"></p-sortIcon>
                        <p-columnFilter *ngIf="col.field === 'description'" type="text" field="{{col.field}}"
                          display="menu"></p-columnFilter>
                        <p-columnFilter *ngIf="col.field !== 'description'" type="numeric" field="{{col.field}}"
                          display="menu" currency="USD"></p-columnFilter>
                      </div>
                    </th>
                  </ng-container>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-dataSource>
                <tr class="p-selectable-row">
                  <td><span [outerHTML]="dataSource?.description | titlecase | isEmpty"></span></td>
                  <td class="align-right-table"><span
                      [outerHTML]="dataSource?.productPrice | currency | isEmpty"></span></td>
                  <td class="align-right-table"><span
                      [outerHTML]="dataSource?.discountRate | currency | isEmpty"></span></td>
                  <td class="align-right-table"><span [outerHTML]="dataSource?.productFee | currency | isEmpty"></span>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="8">No invoices found.</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </section>


  </div>
</div>